header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.header__container > h1 {
    color: var(--color-contrast)
}

/* ================ CTA (CALL TO ACTION) BUTTONS ================ */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

/* ================ SOCIALS ================ */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8frem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-contrast);
}

/* ================ CHATBOT ================ */

.chatbot__container {
    padding: 0.5rem;
    z-index: 2;
    position: fixed;
    left: 69%;
    bottom: 2.5rem;
}
.btn.btn-primary.chatbot__button {
    transform: translateX(8rem);
    border-radius: 1rem;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid var(--color-primary);
    transition: all 200ms ease-in;
}
.btn.btn-primary.chatbot__button:hover {
    background: var(--color-primary-variant);
    border-color: var(--color-contrast);
}
.icon {
    color: white;
    font-size: 1.5rem;
}

iframe {
    width: 20rem;
    height: 20rem;
    background: var(--color-light);
    border-radius: 1rem;
    z-index: 5;
}

/* ================ ME ================ */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 24rem;
    height: 30rem;
    position: absolute;
    /* SAME AS CALC()FUNCTION */
    /* left: 50%
    transform: translateX(-50%) */
    left: calc(50% - 12rem);
    margin-top: 3rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 2rem 1rem;
}

/* ================ SCROLL DOWN ================ */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */

@media screen and (max-width: 1024px) {
    header {
        height: 65vh;
    }

    iframe {
        width: 18rem;
        height: 18rem;
        background: var(--color-light);
        border-radius: 1rem;
        z-index: 5;
    }
    
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
    .header__socials, .scroll__down {
        display: none;
    }

    .chatbot__container {
        visibility: hidden;
    }
}